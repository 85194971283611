<template >
    <div>
        <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header my-top-khan-title"
                >
                    <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">{{$t("message.candidates")}}</p>
                    <el-button @click="closeModal()" type="warning" plain>{{$t("message.close")}}</el-button>
                </div>
            </div>
            <div class="app-modal__body p-5 " v-loading="loadStaff">
               <div class="elemet-content">
                
                <div class="timeline-items__right user rounded-sm w-100 p-4" v-if="quiz_info"  :class="mode ? 'table__myday' : 'table__mynight'">
                  <div class="info-user-left3" :class="mode ? '' : 'performance__itemnight'">
                    <div class="img">
                      <img
                        :src="
                          quiz_info.image
                            ? baseUrl + quiz_info.image.path
                            : '/img/avatar.jpeg'
                        "
                        alt=""
                      />
                    </div>
                    <div class="name-info-user-left">
                      <div class="name_u">
                        <b>{{ $t('message.fio') }}: </b>{{ quiz_info.name + " " + quiz_info.last_name }}
                      </div>
                      <div class="position_u">
                        <b>{{ $t('message.gender') }}: </b>{{ quiz_info.gender?quiz_info.gender.name:'' }}
                      </div>
                      <div class="position_u">
                        <b>{{ $t('message.phone_number') }}: </b>{{ quiz_info.phone_number?quiz_info.phone_number:'' }}
                      </div>
                      <div class="position_u">
                        <b>{{ $t('message.nation') }}: </b>{{ quiz_info.nation?quiz_info.nation.nationality:'' }}
                      </div>
                      <div class="position_u">
                        <b>{{ $t('message.date_of_birth') }}: </b>{{ quiz_info.date_of_birth }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="timeline-items__right rounded-sm w-100 p-4"   :class="mode ? 'table__myday' : 'table__mynight'" v-if="quiz_info">
                  <b>Накопленные баллы: </b>{{ quiz_info.point }} <br>
                  <el-divider>
                    <i class="fa-regular fa-star"></i>
                    Вопросы викторины
                    <i class="fa-regular fa-star"></i>
                  </el-divider>

                  <el-timeline>
                    <el-timeline-item placement="top"
                      v-for="(answer, index) in quiz_info.levels" :key="index+1"
                      :color="answer.point>0?'#0bbd87':'#ff6567'"
                      :icon="answer.point>0?'el-icon-check':'el-icon-close'"
                      :size="'large'"
                      >
                      <el-card>
                        <h4 class="my-1">{{index+1}}. Вопрос: {{answer.question.content}}
                         </h4>
                         <el-image 
                         style="width: 100px; height: 100px"
                         :src="baseUrl+answer.question.media_url" 
                         :preview-src-list="[baseUrl+answer.question.media_url]"
                         v-if="answer.question.media_type=='photo'">
                       </el-image> 
                        <hr>
                        <p class="m-2 mx-3"><b>Ответ: </b>
                          <span v-if="answer.option.media_type!='photo'">{{answer.option.title}}</span>
                        </p>
                        <el-image 
                              style="width: 100px; height: 100px"
                              :src="baseUrl+answer.option.media_url" 
                              :preview-src-list="[baseUrl+answer.option.media_url]"
                              v-if="answer.option.media_type=='photo'">
                            </el-image>
                            <hr>
                        <p class="m-2 mx-3"><b>Балл: </b>{{answer.point}}</p>
                      </el-card>
                    </el-timeline-item>
                  </el-timeline>
                </div>
               </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import closeDialog from "../../../utils/mixins/dialog"
import drawer from "@/utils/mixins/drawer";
export default {
    name: "show-info",
    mixins: [drawer],
    components: {},
    data() {
        return {
          loadStaff: false,
            baseUrl: process.env.VUE_APP_URL.substring(
              0,
              process.env.VUE_APP_URL.length - 3
            ),
        };
    },
    computed: {
    ...mapGetters({
        quiz_info: "candidate/quiz_info",
        mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
        getQuizInfo: "candidate/quiz_info",
    }),

    opened(child) {
      if (!this.loadStaff) {
        this.loadStaff = true;
        this.getQuizInfo(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
      }

    },
    pushToProfile(staff) {
      this.$router.push({
        name: "profile",
        params: { id: staff.id },
      });
    },
    closeModal() {
      this.$emit("closeModal", false);
    },
  }
};
</script>
<style lang="scss" >
.timeline-items__right.user {
  margin-bottom: 20px;
  .company_u{
    margin-bottom: 3px;
    font-size: 14px;
  }
  .info-user-left3 {
    display: flex;
    align-items: start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .img {
      margin-right: 10px;
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 10%;
      }
    }
  }
  .name_u {
    font-weight: bold;
    font-size: 15px;
  }
  .position_u {
    color: #8a8a8a;
    font-size: 12px;
    font-weight: 300;
    margin-top: 2px;
  }
  .salary {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
  }
}
.title_and_button{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.timeline-items__right {
    margin-bottom: 20px;

    .info-user-left2 {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-wrap: wrap;

        .img {
            margin-right: 10px;
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
        }
    }
    .name_u {
        font-weight: bold;
        font-size: 15px;
    }
    .position_u {
        color: #8a8a8a;
        font-size: 12px;
        font-weight: 300;
        margin-top: 2px;
    }
    .salary {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 5px;
    }
}
.info-user-left2{
    display: flex;
    align-items: center;
    //background-color: #fff;
    background-clip: border-box;
  //  border: 1px solid rgba(34,41,47,.1254901961);
    border-radius: 0.428rem;
    // box-shadow: 0 4px 24px 0 rgb(34 41 47 / 1%);
    width: 100%;
    cursor: pointer;
    padding: 10px;
}
.my-top-khan-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 20px;
     flex-wrap: nowrap;
}
.info-user-left2{
  flex-wrap: nowrap !important;
}
.elemet-content{
  padding: 0 15px;
  height: 100%;
  overflow: auto;
}
.app-modal__box{
  min-height: 100vh;
      background-color: #eef2f4;
}
.el-drawer__body{
  overflow: scroll;
}
</style>
